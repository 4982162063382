import React from "react";
import SearchBar from "../components/SearchBar";
import TrackingInfo from "../components/TrackingInfo";
import logo from "../resources/alogo.png";




export default function Track () {
    return(
        <div className="
        textBasic
        flex flex-col h-full w-full
        justify-start items-center p-12
        text-6xl
        mob:text-4xl mob:font-semibold mob:p-0
        ">
            
            <div className="
            aspect-square h-[20%] max-w-full my-[2%] min-h-[20%]
            mob:my-2
            ">
                <img src={logo} className="
                object-contain
                "/>
            </div>
            <div className="
                flex flex-col w-full h-full
                justify-start items-center
                px-[5%] mob:mb-[5%]
                overflow-y-scroll scrollbar
                ">
                <SearchBar />
                <TrackingInfo />
            </div>
        </div>
    )
}


