import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../resources/alogo.png";
import qr from "../resources/splash/arkqr.png"



export default function Home () {
    return(
        <div className="
        
        h-full w-full
        grid grid-cols-2 grid-rows-1 items-center justify-items-center
        mob:grid-cols-1 mob:grid-rows-2 mob:p-8
        ">
            {/* flex flex-row justify-evenly items-center
            mob:flex-col */}
            {/* text-9xl font-semibold text-slate-400 */}
            <div className="
            aspect-square max-h-full max-w-full p-[20%]
            mob:p-16
            ">
                {/* w-full h-full */}
                <NavLink to={'track'} className="">
                    <img src={qr} className="
                    object-contain
                    "/>
                    <div className="
                    homeText
                    border-arkanic-green border-4 rounded-3xl
                    ">
                        Track
                    </div>
                </NavLink>
            </div>
            
            <div className="
            aspect-square max-h-full max-w-full p-[20%]
            mob:p-16
            ">
                {/* w-full h-full */}
                <NavLink to={'story'} className="">
                    <img src={logo} className="
                    object-contain
                    "/>
                    <div className="
                    homeText
                    border-arkanic-green border-4 rounded-3xl
                    ">
                        Story
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

