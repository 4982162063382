import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutRouter from '../pages/AboutRouter';
import Error from '../pages/Error';
import Home from '../pages/Home';
import Shop from '../pages/Shop';
import Track from '../pages/Track';
import Navbar from './Navbar';
import ValuesRouter from '../pages/ValuesRouter';
import ErrorRedirect from '../pages/Redirect/ErrorRedirect';
import TrackRedirect from '../pages/Redirect/TrackRedirect';
import Canvas from '../utilities/Canvas';


export default function App() {
  return (
    <div className="
    
    w-full h-full flex overflow-hidden
    flex-row 
    mob:flex-col
    ">
      <Canvas />
      <Routes>
        <Route index element={<Home />}/>
        <Route path='/' element={<Navbar />}>
          <Route path='track' element={<Track />}/>
          <Route path='track/*' element={<TrackRedirect />}/>
          <Route path='story/*' element={<AboutRouter />} />
          {/* <Route path='values/*' element={<ValuesRouter />}/> */}
          <Route path='shop' element={<Shop />}/>
        </Route>
        <Route path='error' element={<Error />}/>
        <Route path='*' element={<ErrorRedirect />}/>
      </Routes>
    </div>
  );
}

