import React from "react";
import { NavLink, Outlet } from "react-router-dom";





export default function Navbar () {
    return(
        <>
            <div className="
            mainNavBar
            flex flex-shrink-0
            flex-col h-full w-24
            mob:flex-row mob:w-full mob:h-24
            justify-evenly items-center
            ">
                {/* <NavLink className={'mainNavLink'} to={'/'}>Home</NavLink> TESTING ONLY, REMOVE FOR BUILD */}
                <NavLink className={({isActive}) => (isActive ? 'activeMainNavLink' : 'mainNavLink')} to={'story'}>Story</NavLink>
                <NavLink className={({isActive}) => (isActive ? 'activeMainNavLink' : 'mainNavLink')} to={'track'}>Track</NavLink>
                {/* <NavLink className={({isActive}) => (isActive ? 'activeMainNavLink' : 'mainNavLink')} to={'values'}>Values</NavLink> */}
                <NavLink className={({isActive}) => (isActive ? 'activeMainNavLink' : 'mainNavLink')} to={'shop'}>Shop</NavLink>
                {/* <NavLink className={'mainNavLink'} to={'account'}>Account</NavLink> */}
            </div>

            <div className="
            mainNavContent
            h-full w-full
            ">
                <Outlet />
            </div>
        </>
    )
}

