import React from "react";




export default function Shop () {
    return(
        <div className="h-full overflow-y-auto scrollbar">
            <div className="pageBorder">
                <p className="mainTextHead">
                    Pure Food
                    <br />
                    <br />
                    Coming Soon
                </p>
                <p className="mainTextBody">
                    <p className="font-semibold text-4xl">
                        Lettuce
                    </p>
                    <br/>
                    Black Rose <br/>
                    Butterhead <br/>
                    Green Oak <br/>
                    Locarno <br/>
                    Lollo Bionda <br/>
                    Lollo Rosso <br/>
                    Red Oak <br/>
                    Red Romaine <br/>
                    Romaine <br/>
                </p>
                <p className="mainTextBody">
                    <p className="font-semibold text-4xl">
                        Herbs
                    </p>
                    <br/>
                    Salad Arugula (Rocket Leaf)<br/>
                    Wild Arugula (Rocket Leaf)<br/>
                    Italian Basil<br/>
                    Purple Basil<br/>
                    Thai Basil<br/>
                    Coriander<br/>
                    Oregano<br/>
                    Parsley<br/>
                    Peppermint<br/>
                    Rosemary<br/>
                    Thyme<br/>
                </p>
                <p className="mainTextBody">
                    <p className="font-semibold text-4xl">
                        Vegetables
                    </p>
                    <br/>
                    Pak Choi<br/>
                    Scarlet Globe Radish<br/>
                    Spinach<br/>
                </p>
            </div>
        </div>
    )
}

